import styled from "@emotion/styled"
import { colors, animationDuration, breakpoints, dimensions } from "../styles"

export const HistoryMainWrapper = styled.div`
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  width: 100%;
  max-width: ${dimensions.contentWidth};
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const HistoryHeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 830px;
  margin: auto;
  text-align: center;
 
`

export const HeadingMain = styled.h1`
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 1.5rem;
`

export const DescriptionText = styled.div`
  text-align: justify;
`

export const BlockDescription = styled.div`
  max-width: 450px;
  margin-bottom: 30px;
  @media (max-width: ${breakpoints.tablet}px) {
    max-width: 500px;
  }
`

export const DescriptionMain = styled.span`

`

export const BlockWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  margin-bottom: 50px;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

export const Image = styled.img`
  max-height: 400px;
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
    max-width: 300px;
  }
`

export const ImageHeader = styled.img`
  max-height: 400px;
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`

export const ImageVignette = styled.img`
   max-height: 75px;
   margin-bottom: 25px;
   margin-right: 20px;
}
`

export const ButtonForm = styled.button`
  display: flex;
  font-family: Lora;
  cursor: pointer;
  border: 0;
  margin-top: 50px;
  padding: 10px 13px;
  background: ${colors.softBeige};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: ${colors.darkWaskoll};
  outline: none;
  font-size: 1rem;
  width: 200px;
  transition-duration: 0.2s;
  &:hover{
    background: ${colors.beige};
    color: black;
  }
`

export const Divider = styled.div`
  height: 2px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 40px;
  width: 70px;
  background: ${colors.redWaskoll};
`

export const DividerSmall = styled.div`
  height: 2px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 30px;
  width: 70px;
  background: ${colors.redWaskoll};
`

export const ImageBlockWithBackground = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 0px;
  padding-left: 30px;
  background: ${colors.softBeige};
  max-width: ${dimensions.contentWidth};
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    padding: 30px;
  }
`

export const ImageBlockWithoutBackground = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 0px;
  padding-left: 30px;
  background: none;
  max-width: ${dimensions.contentWidth};
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    padding: 0px;
  }
`

export const TwoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
  }
`

export const BlockWithBackground = styled.div`
  display: flex;
  max-width: 450px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 30px;
  background: ${colors.softBeige};
  flex-direction: column;
  align-item: center;
  justify-content: space-around;
  margin: auto;
  text-align: center;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
    margin-bottom: 30px;
  }
`

export const BlockVignette = styled.div`
  display: flex;
  max-width: 450px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 30px;
  background: white;
  flex-direction: column;
  align-item: center;
  justify-content: space-around;
  margin: auto;
  text-align: center;
  box-shadow: 0px 6px 20px 0px rgba(0,0,0,.06);
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
    margin-bottom: 30px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-bottom: 50px;
  };
  @media (max-width: ${breakpoints.mobile}px) {
    margin-bottom: 30px;
  }
`

export const ImageWrapperVignette = styled.div`
  display: flex;
  width: 30%;
  justify-content: center;
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-bottom: 50px;
  };
  @media (max-width: ${breakpoints.mobile}px) {
    margin-bottom: 30px;
  }
`

export const ImageWrapperVignetteCollection = styled.div`
  display: flex;
  width: 40%;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-bottom: 50px;
    width: 100%;
  };
  @media (max-width: ${breakpoints.mobile}px) {
    margin-bottom: 30px;
  }
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-around;
`

export const TitleDescription = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
`

