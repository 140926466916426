import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import {
  ButtonForm,
  HistoryMainWrapper,
  HeadingMain,
  Divider,
  HistoryHeadWrapper,
  ImageBlock,
  ImageWrapper,
  DescriptionWrapper,
  TitleDescription,
  BlockWrapper,
  DescriptionText,
  DescriptionMain,
  DividerSmall,
  BlockDescription,
  Image,
  ImageBlockWithBackground,
  ImageBlockWithoutBackground,
  BlockWithBackground,
  TwoBlock,
  BlockVignette, ImageWrapperVignette, ImageVignette, ImageWrapperVignetteCollection, ImageHeader
} from "./StyledHistoryBlock"
import Img from "gatsby-image"

import IMAGE_FAMILY from "../../images/HISTORY_FAMILY.png"
import IMAGE_PASSION from "../../images/HISTORY_PASSION.png"
import IMAGE_ARCHI from "../../images/HISTORY_ARCHI.png"
import IMAGE_LABEL from "../../images/HISTORY_LABEL.png"
import IMAGE_HEADER from "../../images/HISTORY_HEADER.png"
import IMAGE_COMITE from "../../images/HISTORY_COMITE_VENDOME.png"
import IMAGE_KIMBERLEY from "../../images/HISTORY_KIMBERLEY.png"

import IMAGE_GIA from "../../images/HISTORY_GIA.png"
import IMAGE_GUBELIN from "../../images/HISTORY_GUBELIN.png"
import IMAGE_GEMLAB from "../../images/HISTORY_GEM_LAB.png"
import IMAGE_HRD from "../../images/HISTORY_HRD.png"
import IMAGE_DUNAIGRE from "../../images/HISTORY_DUNAIGRE.png"
import IMAGE_GRS from "../../images/HISTORY_GRS.png"

export default () => {

  const imageSrc = useStaticQuery(graphql`
      query {
          family: file(relativePath: { eq: "HISTORY_FAMILY.png" }) {
              childImageSharp {
                  fluid(maxHeight: 350) {
                      ...GatsbyImageSharpFluid
                  }
              }
          },

      }
  `)

  return (
    <HistoryMainWrapper>
      <HistoryHeadWrapper>
        <div>
          <HeadingMain>
            UNE HISTOIRE DE FAMILLE
          </HeadingMain>
          <Divider/>
        </div>
        <DescriptionMain>
          C’est une histoire de famille comme il en existe peu. Les deux frères Waskoll, héritiers d’une longue tradition de joailliers et de gemmologues comptent parmi les Maisons parisiennes les plus prestigieuses depuis plusieurs décennies.
        </DescriptionMain>
        <br/>
        <DescriptionMain>
          A l’abri des regards, ils franchissent le pas de l’ombre vers la lumière en ouvrant une boutique au 19 rue de la Paix, coeur du sanctuaire du luxe le plus ultime. Ils y révèlent enfin au grand jour toute l’étendue de leur créativité et de leur maîtrise du savoir-faire.
        </DescriptionMain>
        <br/>
        <DescriptionMain>
          Car Cyril et Kirk forment un tandem unique. Au premier l’inventivité et la délicatesse des formes. Au second, le savoir-faire séculaire et rare dans le choix des pierres précieuses. Un duo singulier dans l’univers feutré de la haute joaillerie qui s’accorde avec l’air du temps tout en respectant la plus pure des traditions.
        </DescriptionMain>
        <ButtonForm>
          <span id="savoir-faire" style={{marginLeft: "10px"}}>Savoir Faire</span>
        </ButtonForm>
      </HistoryHeadWrapper>
      {/* FAMILY */}
      <BlockWrapper>
        <ImageBlockWithBackground>
          <ImageWrapper>
            <Image alt={"Waskoll Family"} src={IMAGE_FAMILY}/>
          </ImageWrapper>
          <DescriptionWrapper>
            <BlockDescription>
              <TitleDescription>SAVOIR-FAIRE FAMILIAL</TitleDescription>
              <DividerSmall/>
              <DescriptionText>
                <span>
                  Le patrimoine familial entretenu par les frères Waskoll remonte à plusieurs générations. Il traduit leur engagement quotidien au sein de l’entreprise, mais aussi leur ardeur à préserver une vision et une éthique intactes à celles de leurs ancêtres.
                </span>
              </DescriptionText>
            </BlockDescription>
            <BlockDescription>
              <TitleDescription>LA RÈGLE DE L’ART</TitleDescription>
              <DividerSmall/>
              <DescriptionText>
                <span>Les frères Waskoll exercent leur art selon des codes et des techniques maîtrisés. Rigoureux dans la recherche des pierres précieuses, et créatifs dans leur assemblage, ils garantissent une fabrication selon des règles traditionnelles des plus exigeantes. Le respect de l’ensemble des intervenants garantit par ailleurs l’origine de chaque pierre.</span>
              </DescriptionText>
            </BlockDescription>
          </DescriptionWrapper>
        </ImageBlockWithBackground>
      </BlockWrapper>
      {/* PASSION */}
      <BlockWrapper>
        <ImageBlockWithoutBackground>
          <DescriptionWrapper>
            <BlockDescription>
              <TitleDescription>LA PASSION COMME GUIDE</TitleDescription>
              <DividerSmall/>
              <DescriptionText>
                <span>
                  Seule la passion les guide dans tout leur parcours reconnu par le sérail.<br/> Qu’ils travaillent pour une pièce unique et inestimable ou pour une pièce faisant partie d’une collection, le souci du détail est le même.<br/> La passion pour le métier, identique.<br/> Aux confins de la jungle birmane pour traquer un saphir encore secret, ou devant une page blanche le crayon noir à la main, ils accordent leur talent et subliment un art perdu ancestral. Celui de la maîtrise parfaite de toutes les étapes de la fabrication création de l’objet unique, chéri et convoité depuis des siècles.
                </span>
              </DescriptionText>
            </BlockDescription>
          </DescriptionWrapper>
          <ImageWrapper>
            <Image alt={"Waskoll Passion"} src={IMAGE_PASSION}/>
          </ImageWrapper>
        </ImageBlockWithoutBackground>
      </BlockWrapper>
      {/* ARCHI */}
      <BlockWrapper>
        <ImageBlockWithBackground>
          <ImageWrapper>
            <Image alt={"Waskoll Family"} src={IMAGE_ARCHI}/>
          </ImageWrapper>
          <DescriptionWrapper>
            <BlockDescription>
              <TitleDescription>ARCHITECTES DE LUMIÈRE</TitleDescription>
              <DividerSmall/>
              <DescriptionText>
                <span>Techniciens et artisans, Kirk et Cyril dédient leur existence à cet art rigoureux de la haute joaillerie.<br/> Passés maîtres au fil des années consacrées à la pratique de cette discipline sans filet, ils cumulent à eux deux un savoir-faire unique dans la profession.<br/> En véritables architectes de lumière, ils composent chaque pièce de leurs collections, comme les plus uniques, depuis la première étape jusqu’à la dernière.                </span>
              </DescriptionText>
            </BlockDescription>
          </DescriptionWrapper>
        </ImageBlockWithBackground>
      </BlockWrapper>
      {/* LABEL  */}
      <BlockWrapper>
        <ImageBlockWithoutBackground>
          <DescriptionWrapper>
            <BlockDescription>
              <TitleDescription>UN LABEL D’ÉTAT
              </TitleDescription>
              <DividerSmall/>
              <DescriptionText>
                <span>De la recherche de la pierre là où peu de personnes n’osent encore s’aventurer, jusqu’à la conception de la pièce finale, tous leurs sens sont en éveil. La maîtrise du savoir-faire français de l’atelier Waskoll a permis à la Maison Waskoll d’obtenir l’illustre label d’état EPV (Entreprise du Patrimoine Vivant) qui salue « L’excellence des savoir-faire Français ».</span>
              </DescriptionText>
            </BlockDescription>
          </DescriptionWrapper>
          <ImageWrapper>
            <Image alt={"Waskoll Passion"} src={IMAGE_LABEL}/>
          </ImageWrapper>
        </ImageBlockWithoutBackground>
      </BlockWrapper>
      {/* TWO BLOCK */}
      <BlockWrapper>
        <TwoBlock>
          <BlockWithBackground>
            <TitleDescription>AU COEUR DE LA CAPITALE</TitleDescription>
            <DividerSmall/>
            <DescriptionText>
              <span>
                Capitale inégalée de la haute-joaillerie, Paris rythme la vie des maisons les plus prestigieuses dont Waskoll fait partie. Une tradition perpétuée par un cercle très restreint de professionnels dont l’ultime privilège est d’allier leur destin à celui de la ville Lumière.
              </span>
            </DescriptionText>
          </BlockWithBackground>
          <BlockWithBackground>
            <TitleDescription>PASSION CRÉATIVE</TitleDescription>
            <DividerSmall/>
            <DescriptionText>
              <span>
                Au-delà des codes et de la tradition, la maison Waskoll se singularise par sa créativité. Moderne sans renier les fondamentaux de la discipline, elle s’adresse aux clients les plus exigeants. Son approche est intemporelle et l’excellence s’associe à la sobriété. La recherche de l’émotion sans artéfacts, constante, prime sur la démonstration technique.
              </span>
            </DescriptionText>
          </BlockWithBackground>
        </TwoBlock>
      </BlockWrapper>
      {/* HEADER IMAGE */}
      <BlockWrapper id="distinctions">
        <ImageHeader alt={"Waskoll Passion"} src={IMAGE_HEADER}/>
      </BlockWrapper>
      {/* COMITE VENDOME */}
      <BlockWrapper>
        <ImageBlockWithoutBackground>
          <BlockVignette>
            <TitleDescription>LE COMITÉ VENDÔME​</TitleDescription>
            <DividerSmall/>
            <DescriptionText>
              <span>Place Vendôme, rue de la Paix, rue de Castiglione, rue Saint-Honoré, l’Opéra,…: un quartier dont le prestige date de la volonté du roi Louis XIV qui avait souhaité en faire l’un des emblèmes de la splendeur de son règne.              </span>
              <br/>
              <br/>
              <span>Créé en 1936 pour fédérer les grands joailliers et les orfèvres de la finance installés à cette adresse, le Comité Vendôme a pour mission de préserver «le supplément d’âme de la place Vendôme». Sûreté, culture, architecture : le Comité veille sur le rayonnement de la place Vendôme dans le monde entier. La maison Waskoll est un membre actif de l’illustre Comité Vendôme depuis l’ouverture de la boutique au 19 rue de la Paix.</span>
            </DescriptionText>
          </BlockVignette>
          <ImageWrapperVignette>
            <Image alt={"Waskoll Comite Vendome"} src={IMAGE_COMITE}/>
          </ImageWrapperVignette>
        </ImageBlockWithoutBackground>
      </BlockWrapper>
      {/* KIMBERLEY */}
      <BlockWrapper>
        <ImageBlockWithoutBackground>
          <ImageWrapperVignette>
            <Image alt={"Waskoll Comite Vendome"} src={IMAGE_KIMBERLEY}/>
          </ImageWrapperVignette>
          <BlockVignette>
            <TitleDescription>LE PROCESSUS DE KIMBERLEY​​</TitleDescription>
            <DividerSmall/>
            <DescriptionText>
              <span>
                Le processus de Kimberley est un régime international de certification des diamants bruts, qui réunit gouvernements et négociants du diamant, dans l’objectif d’éviter de commercer sur le marché mondial, l’achat des diamants présentés par des mouvements rebelles dans le but de financer leurs activités militaires.
              </span>
              <br/>
              <br/>
              <span>
                Cette coopération internationale est motivée par le problème des diamants de conflits, des diamants produits dans des zones de guerre et utilisés par des seigneurs de la guerre pour se fournir en armes. La maison Waskoll est défenseur des droits de l’homme et de La Paix dans le monde. Faire partie de ce traité de Kimberley est une façon d’agir contre les guerres. Les diamants Waskoll proviennent donc tous sans exception de zone de non conflits.
              </span>
            </DescriptionText>
          </BlockVignette>
        </ImageBlockWithoutBackground>
      </BlockWrapper>
      {/* CERTIFICATION */}
      <BlockWrapper>
        <ImageBlockWithoutBackground>
          <BlockVignette>
            <TitleDescription>CERTIFICATION​</TitleDescription>
            <DividerSmall/>
            <DescriptionText>
              <span>
                Les diamants Waskoll sont tous certifiés à partir de 0.30Ct. Tous les certificats des diamants proviennent des deux plus prestigieux laboratoires mondiaux : GIA et HRD.
              </span>
              <br/>
              <br/>
              <span>
                L’un situé aux Etats-Unis et le deuxième à Anvers. La plupart des pierres de couleurs sont elles aussi certifiées, notamment si elle présente des particularités de couleur ou de qualité (non chauffée, sang de pigeon,…).
              </span>
              <br/>
              <br/>
              <span>
                La maison Waskoll fait appel aux laboratoires GIA des Etats-Unis, GRS, Dunaigre, GTL, GUBLIN de Suisse, CGL de France.
              </span>
            </DescriptionText>
          </BlockVignette>
          <ImageWrapperVignetteCollection>
            <ImageVignette alt={"Waskoll GIA"} src={IMAGE_GIA}/>
            <ImageVignette alt={"Waskoll Gubelin"} src={IMAGE_GUBELIN}/>
            <ImageVignette alt={"Waskoll Carat GemLab"} src={IMAGE_GEMLAB}/>
            <ImageVignette alt={"Waskoll HRD"} src={IMAGE_HRD}/>
            <ImageVignette alt={"Waskoll Dunaigre"} src={IMAGE_DUNAIGRE}/>
            <ImageVignette alt={"Waskoll GRS"} src={IMAGE_GRS}/>
          </ImageWrapperVignetteCollection>
        </ImageBlockWithoutBackground>
      </BlockWrapper>
    </HistoryMainWrapper>
  )
}
